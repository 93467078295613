import * as React from "react";
import { Link } from "gatsby";
import bcImage from '../images/birthcontrol.gif';
import hurricaneImage from '../images/hurricane.png';
import survivorImage from '../images/survivor.png';
import bruceImage from '../images/bruce.png';
import documentImage from '../images/document.png';
import safetyNetImage from '../images/safetynet.png';
import voterFraudImage from '../images/voterfraud.png';
import electionImage from '../images/election.png';
import jan6Image from '../images/jan6.gif';


const introStyles = {
  margin: '0 auto',
  maxWidth: '700px',
  marginBottom: "4rem",
}

const paragraphStyles = {
  marginBottom: "1.8rem",
  fontSize: '1rem',
}

const listStyles = {
  margin: "0 auto",
  maxWidth: "700px",
  listStyleType: 'none',
  marginBottom: 96,
  marginTop: 80,
  paddingLeft: 0,
}
const listItemStyles = {
  marginBottom: 40,
  textDecoration: "none",
  // display: 'grid',
  gap: '5px'
}


const imageGridStyle = {
  margin: '0 auto', 
  maxWidth: '1200px', 
  // display: 'grid', 
  // gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '20px', 
  gridAutoFlow: 'dense',
  justifyContent: 'stretch',
  padding: '15px',
  justifyContent: 'center'
}

const imageStyle = {
  border: "1px solid #f0f0f0",
  // boxShadow: '0 1px 1px rgba(0, 0, 0, 0.15)',
  border: '1px solid #dedede',
  maxWidth: '100%',
}

const links = [
  {
    text: "From birth to death",
    url: "https://apnews.com/article/from-birth-to-death-series-2b2f56844fe6f309be541b45aef2e281",
    description:
    "A package of stories about health inequities faced by Black Americans.",
    ups: true,  
  },
  {
    text: "Adrift",
    url: "https://apnews.com/article/adrift-investigation-migrants-mauritania-tobago-663a576e233cb4b363f5eda8d5969b5a",
    description:
    "An immersive presentation telling the story of migrants adrift in the Atlanic.",
    ups: true,  
  },
  {
    text: "Garbage collection",
    url: "https://garbagecollection.substack.com/",
    description: "Blogging every now and again about technology, with a focus on computer history.",
    ups: true,  
  },
  {
    text: "Useful document summary stats",
    url: "https://medium.com/fathominfo/useful-document-summary-stats-13d99855b95e",
    description: "Analyzing and visualizing document collections.",
    ups: true,  
  },
  {
    text: "MIT.edu",
    url: "https://mit.edu",
    description:
    "Leveraging Elasticsearch and Vue.js to build a reactive, visual search experience (try typing 'Hacks').",
    ups: true,  
  },
  // {
  //   text: "Little Women, Big Data",
  //   url: "https://garbagecollection.substack.com/p/little-women-big-data/",
  //   description:
  //   "Analyzing the text of Louisa May Alcott's beloved classic for my blog using Python, NLTK, and matplotlib."  
  // },
  {
    text: "Turning the dials in Elasticsearch",
    url: "https://medium.com/stories-from-upstatement/turning-the-dials-in-elasticsearch-4794a63cbbfe",
    description:
    "A quick rundown on tuning open-source search engine Elasticsearch."  
  },
  {
    text: "Collaborating with Craft",
    url: "https://vimeo.com/246865818",
    description:
    "Presentation at Dotall Craft CMS Conference about strategies for small team collaboration."  
  },
  // {
  //   text: "Admins are Users Too",
  //   url: "https://www.youtube.com/watch?v=Xi3FX-LDvJI",
  //   description:
  //   "Presentation at WordCamp for Publishers about building better admin interfaces in WordPress."  
  // },
  {
    text: "Nudging editors in the right direction",
    url: "https://medium.com/stories-from-upstatement/nudging-editors-in-the-right-direction-b6f7425bda76",
    description:
    "Blogging about a WordPress plugin we made to dispense simple design advice directly from the editor interface."  
  },
  {
    text: "Tinkers",
    url: "http://tinkers.lindaggorman.com/",
    description:
    "My senior honors thesis at Syracuse. A compilation of interactives and written and audio stories about robotics and maker culture."  
  },
]

const featuredLinks = [
  {
    title: "Searching r/birthControl",
    image: bcImage,
    link: "/projects/birthcontrol"
  },
  {
    title: "Safety net vs abortion bans",
    image: safetyNetImage,
    link: "https://apnews.com/article/abortion-laws-raising-children-e620ca2a871bfd9ce5b6d6c76e092c31"
  },
  {
    title: "Hurricane-triggered outages",
    image: hurricaneImage,
    link: "/projects/hurricanes"
  },
  {
    title: "Evaluating voter fraud claims",
    image: voterFraudImage,
    link: "https://apnews.com/article/voter-fraud-election-2020-joe-biden-donald-trump-7fcb6f134e528fee8237c7601db3328f"
  },
  {
    title: "Survivor winners",
    image: survivorImage,
    link: "/projects/survivor"
  },
  {
    title: "Midterms 2022",
    image: electionImage,
    link: "/projects/midterms"
  },
  {
    title: "Jan. 6 Prosecutions",
    image: jan6Image,
    link: "https://apnews.com/article/politics-legal-proceedings-crime-new-york-city-donald-trump-7aae07e782e884167579dc9d4b7a4100"
  },
  {
    title: "Bruce's America",
    image: bruceImage,
    link: "/projects/bruce"
  }
  
]

// markup
const IndexPage = () => {
  return (
    <main>
      <title>Linda G. Gorman</title>
      <div style={introStyles}>
        <h1 style={{ fontWeight: 'normal' }}>
          linda g. gorman
        </h1>
        <p style={paragraphStyles}>
        Developer focused on storytelling, visualization, and data. Currently part of the interactive news team at the <a href="https://projects.apnews.com/">Associated Press</a>, previously at <a href="https://storymaps.arcgis.com/" target="_blank">Esri</a>, <a href="https://upstatement.com" target="_blank">Upstatement</a>, and the University of Utah.
        </p>
      </div>
        <div style={imageGridStyle} >
          {featuredLinks.map(item => (
            <div className="image-container">
              <Link to={item.link}>
              <div className="image-title">{item.title}</div>
                <img src={item.image} style={imageStyle}/> 
              </Link>
            </div>
          ))}
      </div>
      <ul style={listStyles}>
        {links.map(link => (
          <li key={link.url} style={{ ...listItemStyles, color: link.color }}>
            <a
              href={`${link.url}?utm_source=starter&utm_medium=start-page&utm_campaign=minimal-starter`}
              target="_blank"
            >
              {link.text}
            </a> — {link.description}
          </li>
        ))}
      </ul>
    </main>
  )
}

export default IndexPage
